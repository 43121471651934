import { Amplify, I18n, Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import styles from 'styles/App.module.scss';
import loginStyles from 'styles/AmplifyLogin.module.scss';
import Body from 'components/Layout/Body';
import Header from 'components/Layout/Header';
import SideNav from 'components/Layout/SideNav';
import isPassValid from 'functions/isPassValid';

import { ReactComponent as MiramosLogo } from 'images/miramos_logo_port_fix.svg';
import { ReactComponent as KmLogo } from 'images/KM_LOGO_Type-1.svg';

import awsExports from './aws-exports';
Amplify.configure(awsExports);


const components = {

    // Header component
    Header() {
        return (<div className={ loginStyles.login__miramosLogo }>
                    <MiramosLogo width='200'/>
                </div>)
    },
    
    // SignIn Component
    SignIn: {
        Header() {
            return ( 
                <div className={ loginStyles.login__text }>
                    <p>ログインしてください</p>
                </div>
            )
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();
            return (
                <div className={ loginStyles.login__resetPasswordButtonWrapper }>
                    <Button
                        className={ loginStyles.login__resetPasswordButton }
                        fontWeight="normal"
                        onClick={ toResetPassword }
                        size="small"
                        variation="link"
                            >パスワードを忘れた方 / パスワード変更
                    </Button>
                </div>
            )
        }
    },

    // ResetPW Component
    ResetPassword: {
        Header() {
            return(
                <div>
                    <p>パスワード再設定用のコードを送付します。</p>
                    <p>ログインID(登録メールアドレス)を入力してください。</p>
                </div>
            )
        },
        Footer() { return(<div></div>) },
    },

    // ResetPWConfirmation Component
    ConfirmResetPassword: {
        Header() {
            return(
                <div>
                    <p>メールで送られてきたコードを入力し、</p>
                    <p>13文字以上の英数記号の組み合わせでパスワードを設定して下さい。</p>
                </div>
            )
        },
        Footer() { return(<div></div>) },
    },

    // Footer Component
    Footer() {
        return (<div className={ loginStyles.login__kmLogo }>
                    <KmLogo width="300" />
                </div>)
    },
};

const formFields = {
    signIn: {
        username: {
            placeholder: 'Eメールアドレス',
        },
        password: {
            placeholder: 'パスワード',
        }
    },
    resetPassword: {
        username: {
            placeholder: 'Eメールアドレス',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'コード'
        },
        confirm_password: {
            placeholder: '確認のためパスワードをもう一度入力'
        },
    },
}


I18n.putVocabulariesForLanguage('ja', {
    'Sign in': 'ログイン',
    'Submit': '確定',
    'Back to Sign In': '戻る',
    'Send code': '送信',
    'Resend Code': 'コードを再送信'
});


// Cunstomized ForgotPw and signin
const services = {

    async handleForgotPasswordSubmit(formData: any) {
        let { username, code, password } = formData;

        const validation = isPassValid(password);
        if ( validation.result === false ) {
            alert( validation.message );
            return Auth.forgotPasswordSubmit( username, code, '', );
        }

        return Auth.forgotPasswordSubmit( username, code, password );
    },

    async handleSignIn(formData: any) {
        let { username, password } = formData;

        // handling localstorage tokens
        localStorage.clear();
        localStorage.setItem( 'userSignedIn', 'yes' );

        // handling logged-in actionLog flag
        localStorage.setItem( 'action-log-loggedin-flag', 'yes' );

        const user = await Auth.signIn({ username, password });

        // クエリパラメータの"next"に入力されているパスにリダイレク
        //  - http://miramos.konicaminolta.jp?next=xxxxxx
        const urlParams = new URLSearchParams(window.location.search);
        const nextParam = urlParams.get('next');
        if (nextParam) {
            const nextUrl = new URL(nextParam, process.env.REACT_APP_HOME_TOP_URL);
            const idt = user.signInUserSession.idToken;
            const jwt = idt.jwtToken;
            nextUrl.searchParams.append('idt', jwt);
            window.location.href = nextUrl.href;
        }

        return user;
    }
};


const App = () => {

    if ( process.env.REACT_APP_DEBUG === 'True' ) {
        console.info('DEBUG MODE is True');
    } else if ( process.env.REACT_APP_DEBUG !== "False" ) {
        console.warn('REACT_APP_DUBUG has to be either True or False but: ', process.env.REACT_APP_DEBUG);
    }

    return (

        <Authenticator
            loginMechanisms={['email']}
            hideSignUp={ true }
            formFields={ formFields }
            components={ components }
            className={ loginStyles.login }
            services={ services }
            >

            {({ signOut, user }) => (
                <div className={ styles.app }>
                    <div className={ styles.app__header }>
                        <Header
                            user={ user }
                            signOut={ signOut }/>
                    </div>
                    <div className={ styles.app__body }>
                        <Body 
                            user={ user }
                            signOut={ signOut }/>
                    </div>
                    <div className={ styles.app__sidenav }>
                        <SideNav 
                            user={ user }
                            signOut={ signOut }/>
                    </div>
                </div>
            )}

        </Authenticator>
    );
}

export default App;


